

body {
  margin: 0;
  font-family: 'Marianne', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F0F0F0;
}

body * {
  font-family: 'Marianne', sans-serif;
}


@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Thin.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Thin_Italic.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Thin_Italic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Regular.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Regular_Italic.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Regular_Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Medium.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Medium_Italic.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Medium_Italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Light.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Light_Italic.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Light_Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-ExtraBold.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-ExtraBold_Italic.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-ExtraBold_Italic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Bold.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Marianne';
  src: url('./fonts/marianne/Marianne-Bold_Italic.woff2') format('woff2'),
       url('./fonts/marianne/Marianne-Bold_Italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SHD-InsignesMilitaires';
  src:  url('./fonts/font-icons/SHD-InsignesMilitaires.eot?8b4c0q');
  src:  url('./fonts/font-icons/SHD-InsignesMilitaires.eot?8b4c0q#iefix') format('embedded-opentype'),
        url('./fonts/font-icons/SHD-InsignesMilitaires.ttf?8b4c0q') format('truetype'),
        url('./fonts/font-icons/SHD-InsignesMilitaires.woff?8b4c0q') format('woff'),
        url('./fonts/font-icons/SHD-InsignesMilitaires.svg?8b4c0q#SHD-InsignesMilitaires') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic_"], [class*=" ic_"] {
  font-family: 'SHD-InsignesMilitaires' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic_close:before {
  content: "\e90a";
}
.ic_collection:before {
  content: "\e900";
}
.ic_left_clic:before {
  content: "\e901";
}
.ic_mouse_wheel:before {
  content: "\e902";
}
.ic_mouse_cursor:before {
  content: "\e90b";
}
.ic_right_clic:before {
  content: "\e903";
}
.ic_rotate_left:before {
  content: "\e904";
}
.ic_rotate_right:before {
  content: "\e905";
}
.ic_visibility_off:before {
  content: "\e906";
}
.ic_visibility:before {
  content: "\e907";
}
.ic_zoom_in:before {
  content: "\e908";
}
.ic_zoom_out:before {
  content: "\e909";
}

button {
  border: none;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  background: #21386B;
  border-radius: 18px;
  color: white;
  font-weight: 600;
  line-height: 1.3;
}

.image-legend {
  overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
}