body {
  margin: 0;
}
canvas {
  display: block;
}
.viewer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  display: flex;
}

.loading-spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

/* POI & image gallery box */
.poi-label {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 12px;
}
.poi-info-wrapper,
.gallery-wrapper {
  position: absolute;
}
.poi-info-inner,
.gallery-inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 30vw;
  max-width: 600px;
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 10;
  overflow-y: auto;
  max-height: 70vh;
  scrollbar-color: #00000029 white;
  scrollbar-width: thin;

}

.poi-info-inner > h2 {
  margin-top: 0;
}
.line-svg {
  position: absolute;
  pointer-events: none;
  z-index: 9;
}
.line-svg line {
  box-shadow: 0px 3px 6px #00000029;
  -webkit-filter: drop-shadow(0px 3px 6px #00000029);
  filter: drop-shadow(0px 3px 6px #00000029);
}
.poi-info button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.poi-info button:hover {
  background-color: #0056b3;
}

.poi-info-actions,
.gallery-actions {
  text-align: center;
}

/* Image gallery */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.image-item {
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  transition: transform 0.3s ease;
}

.image-item img {
  width: 100%;
  height: auto;
}

.image-item:hover .image-container img {
  transform: scale(1.1); /* Zoom on hover */
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /* Ratio 4:3 */
  overflow: hidden;
  border-radius: 2px;
  box-shadow: 0px 0px 6px #06193d4d;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
  transition: transform 0.3s ease;
}

.image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, #00000000 0%, #000000b3 100%);
  color: white;
  box-sizing: border-box;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.image-caption p {
  margin: 0;
  font-size: 10px;
}

.caption i.ic_zoom_in {
  font-size: 16px;
  margin-left: 10px;
}

.gallery-info-actions {
  text-align: center;
}

/* image modal view */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  padding: 0;
  border-radius: 8px;
  position: relative;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 12px 12px 32px 12px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 11;
}

.modal-content {
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  overflow: hidden;
}

.modal-image {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.caption {
  position: absolute;
  bottom: 12px;
  left: 12px;
  font-size: 14px;
  max-height: 30px;
  width: calc(100% - 24px);
}

/* Control panel */
.control-panel * {
  font-family: "Poppins", sans-serif;
  z-index: 10;
}

.control-panel-top-left {
  position: absolute;
  top: 10px;
  left: 10px;
}

.control-panel-bottom-left {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.control-panel-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.control-panel-bottom-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.control-panel-item {
  margin: 20px;
  max-width: 350px;
}

.control-panel-item .button {
  color: #21386b;
  font-weight: 600;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-panel-item .button:hover {
  background-color: #21386b;
  color: white;
}

.control-panel-item .button-photo{
  color:  #ffffff;
  font-weight: 600;
  padding: 8px;
  background: "#21386b";
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-panel-item .button-photo:hover {
  background-color: #142344;
  color: white;
}

.control-panel-item .card {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  padding: 12px;
}

.control-panel h2 {
  margin-top: 0;
}

.control-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.control-item img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.control-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.control-buttons button {
  padding: 5px 10px;
}

.zoom-slider {
  display: flex;
  justify-content: center;
}

.zoom-slider input {
  width: 100%;
}

.title {
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  width: 100vw;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
